"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
/*
  To insert Appcues, we follow the second example of inserting a plugin from the README.
  However, we are faced with the case where the tag that is inserted creates a second tag,
  where the script is actually located.
  So it happened that functions were undefined at the time you called them.
  As a solution, a Promise is created to resolve when the second script tag is inserted in the DOM.
  The way to validate whether the second script tag has already been instantiated is by creating
  an observer object, which when inserted into the DOM with the tag's characteristics, the Promise
  is resolved and thus I have access to the functions.
*/

(function () {
  var APP_ID = '39569';
  var APP_SOURCE = "//fast.appcues.com/".concat(APP_ID, ".js");
  function insertAppcues(userData) {
    var email = userData.email,
      name = userData.name,
      created_at = userData.created_at,
      user_id = userData.user_id,
      master = userData.master,
      payment_kind = userData.payment_kind,
      user_group = userData.user_group,
      user_admin = userData.user_admin,
      account_id = userData.account_id,
      account_name = userData.account_name,
      account_user_group = userData.account_user_group,
      role = userData.role,
      pod = userData.pod,
      path_url = userData.path_url,
      universe_id = userData.universe_id,
      universe_name = userData.universe_name;
    window.Appcues.identify(user_id, {
      email: email,
      name: name,
      created_at: created_at,
      user_id: user_id,
      master: master,
      payment_kind: payment_kind,
      user_group: user_group,
      user_admin: user_admin,
      account_id: account_id,
      account_name: account_name,
      account_user_group: account_user_group,
      role: role,
      pod: pod,
      path_url: path_url,
      universe_id: universe_id,
      universe_name: universe_name
    });
  }
  function insertAppcuesPlugin(userData) {
    var scriptList = document.getElementsByTagName('script');
    if (!isThereAppcuesTag(scriptList)) {
      var _scriptList = _slicedToArray(scriptList, 1),
        firstScript = _scriptList[0];
      var appcuesScript = document.createElement('script');
      appcuesScript.id = APP_SOURCE;
      appcuesScript.async = true;
      appcuesScript.defer = true;
      appcuesScript.src = APP_SOURCE;
      firstScript.parentNode.insertBefore(appcuesScript, firstScript);
      window.AppcuesSettings = {
        enableURLDetection: true
      };
    }
    var appcuesPromise = observeAppcuesScriptTag();
    appcuesPromise.then(function () {
      insertAppcues(userData);
    });
  }
  function isThereAppcuesTag(scriptList) {
    return scriptList.namedItem(APP_SOURCE);
  }
  function observeAppcuesScriptTag() {
    return new Promise(function (resolve) {
      var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
            var _mutation$addedNodes = _slicedToArray(mutation.addedNodes, 1),
              addedNode = _mutation$addedNodes[0];
            if (isAppcuesScriptTag(addedNode)) {
              resolve();
            }
          }
        });
      });
      observer.observe(document.documentElement, {
        childList: true,
        subtree: true
      });
    });
  }
  function isAppcuesScriptTag(addedNode) {
    var regex = /sha256\x2D/;
    return addedNode.tagName === 'SCRIPT' && addedNode.crossOrigin === 'anonymous' && addedNode.integrity.match(regex);
  }
  var _window = window,
    GLOBAL_PARAMETERS = _window.GLOBAL_PARAMETERS;
  insertAppcuesPlugin({
    email: GLOBAL_PARAMETERS.user.email,
    name: GLOBAL_PARAMETERS.user.name,
    created_at: GLOBAL_PARAMETERS.user.created_at,
    user_id: GLOBAL_PARAMETERS.user.id,
    master: GLOBAL_PARAMETERS.user.master,
    payment_kind: GLOBAL_PARAMETERS.user.payment_kind,
    user_group: GLOBAL_PARAMETERS.user.user_group,
    user_admin: GLOBAL_PARAMETERS.user.admin,
    account_id: GLOBAL_PARAMETERS.account ? GLOBAL_PARAMETERS.account.id : null,
    account_name: GLOBAL_PARAMETERS.account ? GLOBAL_PARAMETERS.account.name : null,
    account_user_group: GLOBAL_PARAMETERS.account ? GLOBAL_PARAMETERS.account.user_group : null,
    role: GLOBAL_PARAMETERS.user.role,
    pod: GLOBAL_PARAMETERS.user.pod,
    path_url: GLOBAL_PARAMETERS.url_path,
    universe_id: GLOBAL_PARAMETERS.universe ? GLOBAL_PARAMETERS.universe.id : null,
    universe_name: GLOBAL_PARAMETERS.universe ? GLOBAL_PARAMETERS.universe.name : null
  });
})();